.d-flex {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    justify-content: space-between;
}

.d-flex li {
    margin: 20px;
    max-width: 200px;
    max-height: 400px;
}